/* .news-feature {
  background: var(--color-footer);
  color: #ffffff;
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-bottom: 3rem;
}

.news-feature img {
  max-width: 100%;
}

.news-feature h3 {
  font-size: 2rem;
  font-weight: 800;
  color: #fff;
  margin-bottom: 3rem;
  cursor: pointer;
}

.news-feature h3 a:hover {
  text-decoration: underline;
}

.news-feature p,
.news-feature span {
  font-size: 1rem;
  line-height: 35px;
}

.news-feature .content {
  padding: 2rem;
}

.news-feature .read-full a {
  background: #1d354e;
  display: block;
  margin-top: 1rem;
  padding: 0.6rem;
  text-align: center;
}

.news-feature .read-full a:hover {
  background: #364e67;
} */


.news-feature {
  background: var(--color-footer);
  color: #ffffff;
  display: flex;
  flex-direction: column;
  margin-bottom: 3rem;
  padding: 1rem;
}

.news-feature img {
  width: 100%;
  height: auto;
  max-height: 400px; /* Adjust the maximum height as needed */
  object-fit: cover;
  margin-bottom: 1rem;
}

.news-feature h3 {
  font-size: 1.5rem; /* Adjust the font size as needed */
  font-weight: 800;
  color: #fff;
  margin-bottom: 0.5rem; /* Adjust the margin as needed */
  cursor: pointer;
}

.news-feature h3 a:hover {
  text-decoration: underline;
}

.news-feature p,
.news-feature span {
  font-size: 0.9rem; /* Adjust the font size as needed */
  line-height: 1.3;
  margin-bottom: 0.5rem; /* Adjust the margin as needed */
}

.news-feature .content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1rem;
  flex-grow: 1;
}

.news-feature .read-full a {
  background: #1d354e;
  display: inline-block;
  margin-top: 1rem;
  padding: 0.6rem;
  text-align: center;
}

.news-feature .read-full a:hover {
  background: #364e67;
}


