.news {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

@media screen and (max-width: 550px) {
    .news {
        flex-direction: column;
        gap: 0;
    }
  }
  