.features-container-feature {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 1rem;
}

.features-container-feature-title {
  flex: 1;
  max-width: 180px;
  margin-right: 2rem;
}

.features-container-feature-title h1 {
  font-family: var(--font-family);
  font-weight: 800;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: -0.04em;
  color: #fff;
}

.features-container-feature-title div {
  width: 38px;
  height: 3px;
  background: var(--gradient-bar);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  margin-bottom: 0.25rem;
}

.features-container_feature-text {
  flex: 2;
  max-width: 390px;
  display: flex;
}

.features-container_feature-text p {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: var(--color-text);
}

@media screen and (max-width: 550px) {
  .features-container-feature-title h1 {
    font-size: 14px;
    line-height: 22px;
  }

  .features-container_feature-text p {
    font-size: 12px;
    line-height: 20px;
  }

  .features-container-feature {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 1rem;
  }
}
