.possibility {
  display: flex;
  flex-direction: row;
}

.possibility img {
  border-radius: 1rem;
  border: 1px solid #71e5ff;
  max-width: 100%;
}

.possibility .content {
  margin-left: 2rem;
}

.possibility .content h4 {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;
  color: #71e5ff;
}

.possibility .content h1 {
  font-family: var(--font-family);
  font-weight: 800;
  font-size: 34px;
  line-height: 45px;
  margin: 1rem 0;
}

.possibility .content p {
  color: var(--color-text);
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  margin-bottom: 2rem;
}

@media screen and (max-width: 950px) {
  .possibility {
    flex-direction: column;
  }

  .possibility-image {
    margin: 1rem 0;
  }

  .possibility-image img {
    width: unset;
    height: unset;
  }

  .possibility-content {
    margin-top: 2rem;
  }
}

@media screen and (max-width: 700px) {
  .possibility-image img {
    width: 100%;
    height: 100%;
  }
}
