.info .heading {
  margin-bottom: 2rem;
}

.info .heading {
  text-align: center;
}

.info .heading h1 {
  font-size: 34px;
  line-height: 45px;
  font-weight: 800;
  font-family: var(--font-family);
}

.info .sub-heading {
  font-size: 24px;
  font-weight: 500;
  font-family: var(--font-family);
  color: #fff;
}

.info .container-x {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.info .container-y {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.welcome {
  background: var(--color-footer);
  background: radial-gradient(
    circle at 30% -100%,
    #042c54 25%,
    rgba(4, 44, 84, 1) 85%,
    rgba(27, 120, 222, 1) 100%
  );
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}
